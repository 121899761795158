import { Route, Switch } from 'react-router'
import { AboutUs, ContactUs, Landing, StartUp, WhyUs, Work } from '../pages'

const Navigation = () => {
    return (
        <Landing />
        // <Switch>
        //     <Route exact path="/" component={Landing} />
        //     <Route exact path="/services" component={Services} />
        //     <Route exact path="/why-us" component={WhyUs} />
        //     <Route exact path="/work" component={Work} />
        //     <Route exact path="/start-up" component={StartUp} />
        //     <Route exact path="/about-us" component={AboutUs} />
        //     <Route exact path="/contact-us" component={ContactUs} />
        // </Switch>
    )
}

export default Navigation
