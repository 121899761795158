export default {
    pageTop: {
        title: <h3>Some of our projects</h3>,
        text: (
            <p>
                Whether new clients or returning clients, we take pleasure in collaborating across different projects
                and industries! Here&apos;s just some of the clients we have worked with so far…
            </p>
        )
    },

    // projects: {
    //     features: [
    //         {
    //             imgUrl: iphone,
    //             alt: 'Mobile',
    //             href: 'https://www.ubiquitousinfluence.com',
    //             title: 'UBIQUITOUS',
    //             text: 'Digital Trons Designed and eveloped a sport s analutics mobile app for coaches to use on the field. They also developed an API to a preexisting web app with a separate development team.'
    //         },
    //         {
    //             imgUrl: flylady,
    //             alt: 'Laptop',
    //             href: 'https://apps.apple.com/us/app/flyladyplus/id1115477521',
    //             title: 'FLY LADY',
    //             text: 'Digital Trons Designed and eveloped a sport s analutics mobile app for coaches to use on the field. They also developed an API to a preexisting web app with a separate development team.'
    //         },
    //         {
    //             imgUrl: flourish,
    //             alt: 'Laptop',
    //             href: 'https://www.flourishsoftware.com',
    //             title: 'FLOURISH',
    //             text: 'Digital Trons Designed and eveloped a sport s analutics mobile app for coaches to use on the field. They also developed an API to a preexisting web app with a separate development team.'
    //         },
    //         {
    //             imgUrl: vaultlogik,
    //             alt: 'Laptop',
    //             href: 'https://vaultlogik.webflow.io/',
    //             title: 'VAULT LOGIK',
    //             text: 'Digital Trons Designed and eveloped a sport s analutics mobile app for coaches to use on the field. They also developed an API to a preexisting web app with a separate development team.'
    //         }
    //     ]
    // },
    projects: {
        href1: 'https://www.ubiquitousinfluence.com',

        href2: 'https://apps.apple.com/us/app/flyladyplus/id1115477521',

        href3: 'https://www.flourishsoftware.com',

        href4: 'https://vaultlogik.webflow.io/'
    }
}
