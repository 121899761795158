import {
    AboutUsPageTop,
    Aeroplane,
    BoxCircles,
    Boxes,
    BrowserLandscape,
    BrowserWindow,
    Krunal,
    Team,
    TiltedSquare,
    TiltedTriangle
} from 'assets/img'

export default {
    pageTop: {
        title: 'Who are we?',
        text: 'Professional and friendly with our clients and coworkers, we treat each other as we would want to be treated. Our working environment emphasises equality, transparency... and a pet-friendly atmosphere! That&aps;s what Digital Trons is all about :)',
        buttonText: 'Contact Us',
        imgUrl: AboutUsPageTop
    },
    aboutUs: {
        title: 'ABOUT US',
        text: 'Digital Trons offers comprehensive digital & technology solutions to startups, entrepreneurs, and growing businesses around the world. Digital Trons offers comprehensive digital & technology solutions to startups, entrepreneurs, and growing businesses around the world. Digital Trons offers comprehensive digital & technology solutions to startups, entrepreneurs, and growing businesses around the world.',
        imgUrl: Team,
        alt: 'Team',
        features: [
            {
                top: {
                    large: '5',
                    small: 'Yrs'
                },
                bottom: 'Company Experience'
            },
            {
                top: {
                    large: '40',
                    small: '+'
                },
                bottom: 'Abitious Projects'
            },
            {
                top: {
                    large: '20',
                    small: '+'
                },
                bottom: 'Happy Clients'
            },
            {
                top: {
                    large: '99',
                    small: '%'
                },
                bottom: 'Positive Feedback'
            }
        ]
    },
    overview: {
        title: 'COMPANY OVERVIEW',
        features: [
            {
                imgUrl: BrowserWindow,
                alt: 'What we do',
                title: 'What we do',
                text: 'We help our customers design and build mobile and web apps to bring boid ideas to the market',
                buttonText: 'SERVICES',
                route: {
                    id: 2,
                    section: 'services-we-offer'
                }
            },
            {
                imgUrl: BoxCircles,
                alt: 'How we do',
                title: 'How we do',
                text: 'We help our customers design and build mobile and web apps to bring boid ideas to the market',
                buttonText: 'WHY US',
                route: {
                    id: 4,
                    section: ''
                }
            },
            {
                imgUrl: BrowserLandscape,
                alt: 'Our Work',
                title: 'Our Work',
                text: 'We help our customers design and build mobile and web apps to bring boid ideas to the market',
                buttonText: 'OUR WORK',
                route: {
                    id: 5,
                    section: ''
                }
            }
        ]
    },
    culture: {
        title: 'WORK CULTURE',
        text: 'We stand by our values. Fromr the way we work with our clients to the way we interact with each other, these four values influence everuthing that we do:',
        features: [
            {
                imgUrl: TiltedTriangle,
                alt: 'Titled Triangle',
                title: 'Make Great Things',
                text: "Whatever project we're working on, we're constantly raising the bar in terms of quality."
            },
            {
                imgUrl: Aeroplane,
                alt: 'Aeroplan',
                title: 'Deliver Results',
                text: "Whatever project we're working on, we're constantly raising the bar in terms of quality."
            },
            {
                imgUrl: TiltedSquare,
                alt: 'Titled Square',
                title: 'Always Learning',
                text: "Whatever project we're working on, we're constantly raising the bar in terms of quality."
            },
            {
                imgUrl: Boxes,
                alt: 'Boxes',
                title: 'Have Fun',
                text: "Whatever project we're working on, we're constantly raising the bar in terms of quality."
            }
        ]
    },
    team: {
        title: (
            <>
                Our Talented and <span className="text-white">experienced</span> team
            </>
        ),
        features: [
            {
                imgUrl: Krunal,
                alt: 'Team Member',
                title: 'Krunal Thakkar',
                text: 'CEO & Software Engineer'
            },
            {
                imgUrl: Krunal,
                alt: 'Team Member',
                title: 'Krunal Thakkar',
                text: 'CEO & Software Engineer'
            },
            {
                imgUrl: Krunal,
                alt: 'Team Member',
                title: 'Krunal Thakkar',
                text: 'CEO & Software Engineer'
            },
            {
                imgUrl: Krunal,
                alt: 'Team Member',
                title: 'Krunal Thakkar',
                text: 'CEO & Software Engineer'
            },
            {
                imgUrl: Krunal,
                alt: 'Team Member',
                title: 'Krunal Thakkar',
                text: 'CEO & Software Engineer'
            },
            {
                imgUrl: Krunal,
                alt: 'Team Member',
                title: 'Krunal Thakkar',
                text: 'CEO & Software Engineer'
            }
        ]
    }
}
