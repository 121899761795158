import {
    AboutUs,
    ClientHandshake,
    ClientPlaceholder,
    GuaranteeCircles,
    GuaranteeMessage,
    GuaranteeShield,
    GuaranteeThumb,
    LandingPageTop,
    ProcessDefine,
    ProcessDeliver,
    ProcessDesign,
    ProcessDevelop,
    ProcessDiscuss,
    ServicesGameDevelopment,
    ServicesMobileDevelopment,
    ServicesUIUXDesign,
    ServicesWebDevelopment,
    Wave,
    ClientsBbdsaLogo,
    ClientsColaLogo,
    ClientsFlourishLogo,
    ClientsUbqLogo,
    ClientsUnknownLogo,
    ClientsVaultlogikLogo
} from 'assets/img'
import success from '../../assets/img/landing/success.svg'
import best from '../../assets/img/landing/best.svg'
import productDev from '../../assets/img/landing/productDev.svg'

import { Link } from 'react-router-dom'

export default {
    pageTop: {
        heading: {
            top: (
                <>
                    INNOVATION
                    <br /> THROUGH
                </>
            ),
            bottom: 'EMERGING TECHNOLOGY'
        },
        bgImage: [
            {
                imgUrl: LandingPageTop,
                alt: 'Page Top'
            },
            {
                imgUrl: Wave,
                alt: 'Wave'
            }
        ]
    },
    whyUs: {
        heading: {
            main: 'WHY US'
        },
        text: {
            heading: (
                <>
                    Your <span className="linearGradientText">vision</span>, our{' '}
                    <span className="linearGradientText">mission</span>
                </>
            )
        },
        features: [
            {
                imgUrl: <img src={success} alt="gimg" />,
                alt: 'Your Success',
                title: 'Your Success',
                text: 'Early stage startup or growing business - we are your designated technology partner. Integrate with a dedicated team focused on your success.'
            },
            {
                imgUrl: <img src={best} alt="gimg" />,
                alt: 'Communicate Well & Be Transparent',
                title: 'Strive for best',
                text: 'We guarantee high quality solutions and products through our ideation, innovation and execution. We utilize our expertise to deliver the best results in every project.'
            },
            {
                imgUrl: <img src={productDev} alt="gimg" style={{ width: '80px', height: '80px' }} />,
                alt: 'Product Development',
                title: 'Product Development',
                text: 'Talented developers, project managers and designers - we have the right team to create exceptional web and mobile apps for you'
            }
        ],
        buttonText: 'KNOW MORE'
    },
    howWeDo: {
        heading: 'WE TURN IDEAS INTO SOFTWARE - THAT PEOPLE LOVE',
        text: (
            <>
                You have great ideas that keeps you up at night. Let&apos;s realize it together! We are a results driven
                tech partner that brings your ideas to life and turns it into high growth business opportunities.
            </>
        )
    },
    services: {
        heading: {
            main: 'OUR SERVICES',
            text: 'We are a one-stop studio for product development. Having worked with startups for 5+ years and launched over 40 successful products, we offer all the necessary expertise to bring your bold idea to market.'
        },

        buttonText: `Let's Talk Biz`,
        text: 'From scoping out your idea to building fully fledged websites and applications - checkout the overview of services we offer. Get in touch to discss your project so we can tell you exactly how we can help!',
        features: [
            {
                imgUrl: ServicesWebDevelopment,
                alt: 'Services Product Development',
                title: 'Product Development',
                text: (
                    <>
                        Talented product people, developers and designers - we have the rightteam to create exceptional
                        web or mobile applications for you,{' '}
                    </>
                )
            },
            {
                imgUrl: ServicesMobileDevelopment,
                alt: 'Services Dedicated Tech Teams',
                title: 'Dedicated Tech Teams',
                text: (
                    <>
                        Working accross traditonal and emmerging technologies and with a keen eye for creating seamless
                        applications, we work with dedicated engineers who can work alongside your team however you need
                        to give your flexibility{' '}
                    </>
                )
            },
            {
                imgUrl: ServicesGameDevelopment,
                alt: 'Services Talent Scouting',
                title: 'Talent Scouting',
                text: (
                    <>
                        Whatever skills your require, we help you find the right people for the job, with our network of
                        highly skilled developers and designers ready to work with you.{' '}
                    </>
                )
            },
            {
                imgUrl: ServicesUIUXDesign,
                alt: 'Services Strategizing',
                title: 'Strategizing',
                text: (
                    <>
                        Together, we can help you take your fledgling idea and set it up for success, taking stick of
                        the competitive landscape and defining the value you want to deliver.{' '}
                    </>
                )
            }
        ]
    },
    process: {
        heading: {
            main: 'PROCESS',
            sub: {
                title: 'The road to making something special...',
                text: "From beginning to end, and with flexibility throughout. Here's how a typical project with us might look."
            }
        },
        features: [
            {
                imgUrl: ProcessDiscuss,
                alt: 'Process Idea',
                title: 'Idea',
                text: (
                    <>
                        What is your idea? How are we bringing it to life and make it a success? We work with you to
                        fully understand your vision.
                    </>
                )
            },
            {
                imgUrl: ProcessDefine,
                alt: 'Process Scope',
                title: 'Scope',
                text: (
                    <>
                        Together, we&apos;ll hash out your scope, define how the product will look, what features there
                        will be and define any other requirements to be clear on the plan before we begin.
                    </>
                )
            },
            {
                imgUrl: ProcessDesign,
                alt: 'Process Design',
                title: 'Design',
                text: (
                    <>
                        From wireframes to prototypes and high-fidelity mockups, we work on the look and feel of the
                        product and overall UX.
                    </>
                )
            },
            {
                imgUrl: ProcessDevelop,
                alt: 'Process Develop',
                title: 'Develop',
                text: (
                    <>
                        It&apos;s then over to our superb developersm who will find the best techonologies to bring
                        mock-ups to life, keeping you updated at each development milestone.
                    </>
                )
            },
            {
                imgUrl: ProcessDeliver,
                alt: 'Process Deliver',
                title: 'Deliver',
                text: (
                    <>
                        Following through testing, it&apos;s go time! Launch, celebrate - and think about what we are
                        going to do next to help you keep growing.
                    </>
                )
            }
        ]
    },
    bookSession: {
        text: (
            <>
                We take great care to match the technical approach to the vision, not the other way around. We work with
                various advanced technologies to build the best solutions{' '}
            </>
        ),
        buttonText: 'GET IN TOUCH'
    },
    aboutUs: {
        title: 'ABOUT US',
        imgUrl: AboutUs,
        alt: 'About',
        text: {
            top: <>We&apos;re experts in belnding design and tech to make exceptional products.</>,
            bottom: {
                title: 'Since 2015',
                text: 'At Digital Trons, we delight in collaborating with start-ups, entrepreneurs and up-and-coming businesses to help them realise the full potential of their amazing ideas through seamless, exceptional execution.',
                text2: 'From scoping out your initial ideas and concepts to choosing the best tech to build and bring them to fruition, our priority is to bring your vision to life!',
                buttonText: <Link to="#">Know more</Link>
            }
        }
    },
    clients: {
        heading: {
            main: 'CLIENTS'
        },
        title: 'Who have we worked with?',
        description: `Whether new clients or returning clients, we take pleasure in collaborating across different projects and industries! Here's just some of the clients we have worked with so far…`,
        features: [
            {
                imgUrl: ClientsColaLogo,
                alt: 'Client Name'
            },
            {
                imgUrl: ClientsUnknownLogo,
                alt: 'Client Name'
            },
            {
                imgUrl: ClientsFlourishLogo,
                alt: 'Client Name'
            },
            {
                imgUrl: ClientsBbdsaLogo,
                alt: 'Client Name'
            },
            {
                imgUrl: ClientsVaultlogikLogo,
                alt: 'Client Name'
            }
        ]
    },
    clientReview: {
        title: 'SEE WHAT OUR CLIENTS SAY',
        review1: {
            id: '01',
            name: 'Alex Elsea',
            location: 'CEO, Ubiquitous Agency',
            review: `I would give Digital Trons 5 stars across the following categories: tech expertise, ownership, speed, communication and expertise with industry standard tooling & processes. Not only did we get technology resources to develop our app but we got a fractional CTO from the Digital Trons team, which gave us the guidance we needed while also being budget-friendly.`
        },
        review2: {
            id: '02',
            name: 'Colton Griffin',
            location: 'CEO, Flourish Software',
            review: `We’ve been with Digital Trons since we launched (almost 7 years ago) and that’s a testament to how we feel about this team! As a well-seasoned team with exceptional technical skills, DIGITAL TRONS has proven to be an indispensable development partner.`
        },
        review3: {
            id: '03',
            name: 'Diane Bloodworth',
            location: 'CEO, Competitive Sports Analysis',
            review: `Their team is excellent at overcoming obstacles and managed the project well. Determined staff and excellent value are commonplace at Digital Trons.`
        }
    }
}
