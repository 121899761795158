import clsx from 'clsx'
import { BookNowButton } from 'components/atoms'
import { GreenButton } from 'components/atoms'
import { Link } from 'react-router-dom'
import { navLinks } from './NavLinks'
import './PageFooter.scss'
import PageFooterimg from '../../assets/img/landing/PageFooterimg.png'
import PageFooterimg2 from '../../assets/img/landing/PageFooterimg2.png'

import FooterLogo from '../../assets/img/landing/FooterLogo.svg'
import linkedin from '../../assets/img/landing/linkedin.svg'
import facebook from '../../assets/img/landing/facebook.svg'
import twitter from '../../assets/img/landing/twitter.svg'

const navLinksFooter = [...navLinks].slice(0, navLinks.length - 1)

const PageFooter = () => {
    return (
        <>
            <div className="pagefootertop block-black flex flex-row " style={{ opacity: '0.97' }}>
                <div className="PageFooterimg  ">
                    <img src={PageFooterimg} alt="PageFooter" style={{ width: '100%', height: '100%' }} />
                </div>
                <div className="PageFooterimg2  ">
                    <img src={PageFooterimg2} alt="PageFooter" style={{ width: '100%', height: '100%' }} />
                </div>
                <div className="pagefootertopcontent  ">
                    <div className="pagefootertopcontentleft">
                        <div>
                            <img src={FooterLogo} alt="Brand" />
                        </div>
                        <h2 className="footerheading mt-3 text-font-heading text-white text-3xl">Digital Trons</h2>
                        <div className="mt-3 text-center ">
                            <GreenButton
                                onClick={() =>
                                    window
                                        .open('https://calendly.com/krunal-thakkar/free-consultation', '_blank')
                                        .focus()
                                }
                                style={{ width: '200px' }}
                                className="footer-book-link"
                            >
                                <span className="footer-book-link-text">CONSULT WITH US</span>
                            </GreenButton>
                        </div>
                        <div className="footer-nav-links mt-5  grid grid-cols-2 gap-4 w-1/2">
                            {navLinksFooter.map(({ text, route }) => (
                                <div key={Math.random()} className="flex justify-between">
                                    <a href={route} className="font-base w-full text-center text-white">
                                        {text}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="pagefootertopcontentright">
                        <div className="footer-email">
                            <a
                                href="mailto:hello@digitaltrons.com"
                                className="footer-email-link text-center text-white "
                            >
                                hello@digitaltrons.com
                            </a>
                        </div>
                        <div className="footerlinks flex flex-row  justify-between">
                            <a href="https://www.linkedin.com/company/digital-trons/" target="blank">
                                <img src={linkedin} alt="linkedin" style={{ width: '40px' }} />
                            </a>
                            <a href="https://www.facebook.com/digitaltrons" target="blank">
                                <img src={facebook} alt="facebook" style={{ width: '40px' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="block-black p-2">
                <p className="footer-copy text-font text-white text-center text-1xl" style={{ fontSize: '0.7rem' }}>
                    Copyright @ 2022 Digital Trons
                </p>
            </footer>
        </>
    )
}

export default PageFooter
