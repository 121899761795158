import React from 'react'
import { LandingPageText } from 'assets/text'
import { ServicesGameDevelopment } from 'assets/img'
import { ServicesWebDevelopment } from 'assets/img'
import { ServicesMobileDevelopment } from 'assets/img'
import { ServicesUIUXDesign } from 'assets/img'
import Webbase from '../../assets/img/landing/Webbase.svg'
import UiUxbase from '../../assets/img/landing/UiUxbase.svg'

import Gamebase from '../../assets/img/landing/Gamebase.svg'

import Mobilebase from '../../assets/img/landing/Mobilebase.svg'
import serviceHover from '../../assets/img/landing/serviceHover.svg'
import serviceLine from '../../assets/img/services/serviceLine.svg'
import Fade from 'react-reveal/Fade'

import './Service.scss'
function Service() {
    const { services } = LandingPageText

    return (
        <div className="relative block block-black " id="services">
            <div className="service-block-content flex flex-col items-center">
                <div className="service-ht flex flex-col p-3 ">
                    <h3 className="service-head text-center text-font-heading  text-white section-sub-heading ">
                        {services.heading.main}
                    </h3>
                    <p className="text-white text-center mt-5 p-2">{services.heading.text}</p>
                </div>
                {/* <div className="relative service-hover-img flex flex-row items-center justify-center">
                        <img src={serviceHover} alt="service-hover" style={{ width: '150px' }} />
                        <p className="absolute text-white" style={{ width: '80px' }}>
                            HOVER TO KNOW MORE
                        </p>
                    </div> */}

                <div className="services mt-10 mb-20">
                    <div className="service-card flex  flex-col ">
                        <div className="service-details flex flex-row" style={{ width: '100%' }}>
                            <div className=" service-details-ph flex flex-col pt-5">
                                {/* <h2 className="service-text-heading text-font-heading text-white pl-5">01</h2> */}
                                <h2 className="service-text-heading text-font-heading text-white pl-5">
                                    Web development
                                </h2>
                                <div className="service-details-p p-5">
                                    <p className=" mt-2 service-text text-white feature-text service-text-1">
                                        In today’s marketplace, it is vital that your company has access to the most
                                        up-to-date Internet technologies. We offer full-stack solutions to develop
                                        robust and high performance custom web apps. Our proficient & experienced team
                                        has built scalable, enterprise-grade solutions that stand up to rigorous use
                                        across a variety of business verticals.
                                    </p>
                                </div>
                            </div>
                            <Fade right>
                                <div className="service-details-i">
                                    <img className="service-img-base" src={UiUxbase} absolute alt="img-base" />
                                </div>
                            </Fade>
                        </div>
                        <img src={serviceLine} alt="serviceLine" style={{ width: '100vw', height: '20px' }} />
                    </div>

                    <div className="service-card flex  flex-col">
                        <div className="service-details flex flex-row" style={{ width: '100%' }}>
                            <Fade left>
                                <div className="service-details-i relative flex flex-row items-center justify-center">
                                    <img className=" service-img-base" src={Webbase} alt="img-base" />
                                </div>
                            </Fade>

                            <div className=" service-details-ph flex flex-col pt-5">
                                {/* <h2 className="service-text-heading text-font-heading text-white text-right pr-5">
                                    02
                                </h2> */}
                                <h2 className="service-text-heading text-font-heading text-white text-right pr-5">
                                    MOBILE development
                                </h2>
                                <div className="service-details-p">
                                    <p className=" mt-2 service-text text-white text-right feature-text service-text-2">
                                        We design and develop beautiful iOS and Android mobile apps that have
                                        outstanding usability, performance & UI/UX. Our talented minds have successfully
                                        crafted and delivered mobile apps for all size companies with use of native &
                                        hybrid technologies. We map out the experiences that drive customers and
                                        stakeholders to be more loyal, valuable and productive.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <img src={serviceLine} alt="serviceLine" style={{ width: '100vw', height: '20px' }} />
                    </div>

                    <div className="service-card flex  flex-col">
                        <div className="service-details flex flex-row" style={{ width: '100%' }}>
                            <div className=" service-details-ph flex flex-col  pt-5">
                                <h2 className="service-text-heading text-font-heading text-white pl-5">
                                    Cloud Technologies
                                </h2>
                                <div className="service-details-p p-5">
                                    {/* <h2 className="service-text-heading text-font-heading text-white ">03</h2> */}

                                    <p className="  mt-2 service-text text-white feature-text service-text-1">
                                        Our developers possess the necessary expertise and experience for a
                                        forward-thinking cloud strategy. Cloud computing is nothing more than a new
                                        delivery model and we get it. Whether you are looking to build cloud native apps
                                        or migrate to new cloud infrastructure, we can help. You will start reaping the
                                        benefits in terms of agility, scalability and cost savings that cloud computing
                                        provides.
                                    </p>
                                </div>
                            </div>
                            <Fade right>
                                <div className=" service-details-i relative flex flex-row items-center justify-center">
                                    <img className=" service-img-base" src={Gamebase} alt="img-base" />
                                </div>
                            </Fade>
                        </div>
                        <img src={serviceLine} alt="serviceLine" style={{ width: '100vw', height: '20px' }} />
                    </div>

                    <div className="service-card flex  flex-col">
                        <div className="service-details flex flex-row" style={{ width: '100%' }}>
                            <Fade left>
                                <div className="service-details-i relative flex flex-row items-center justify-center">
                                    <img className=" service-img-base" src={Mobilebase} alt="img-base" />
                                </div>
                            </Fade>

                            <div className=" service-details-ph flex flex-col pt-5">
                                {/* <h2 className="service-text-heading text-font-heading text-white text-right pr-5">
                                    04
                                </h2> */}
                                <h2 className="service-text-heading text-font-heading text-white text-right pr-5">
                                    Team Augmentation
                                </h2>

                                <div className="service-details-p ">
                                    <p className="mt-2 service-text text-white text-right feature-text service-text-2">
                                        At Digital Trons, we understand every project is unique. We form a dedicated
                                        team as per your needs and the skills you are looking to bring on. Team works
                                        under your guidance and follows processes you have already established. This
                                        model serves as a great choice to reduce the cost and augment your onsite team.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <img src={serviceLine} alt="serviceLine" style={{ width: '100vw', height: '20px' }} />
                    </div>
                </div>
            </div>
        </div>
        // <div className="block block-black block-padded mt-10" id="services">
        //     {' '}
        //     <div className="block-content">
        //         <div className="flex flex-col">
        //             <h3 className="service-head text-font-heading text-center text-white section-sub-heading">
        //                 {services.heading.main}
        //             </h3>
        //             <p className=" service-text mt-2 text-center text-white section-description">{services.text}</p>
        //             <div className="service-top-button mx-auto">
        //                 <div className="mt-12 text-center">
        //                     <GreenButton className="w-52">
        //                         <a href="mailto:hello@digitaltrons.com">{services.buttonText}</a>
        //                     </GreenButton>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className="small-display">
        //             <div
        //                 className="hex-layer-1"
        //                 style={{
        //                     position: 'relative',
        //                     height: '28.75rem'
        //                 }}
        //             >
        //                 <div
        //                     className="hexagon left "
        //                     style={{
        //                         position: 'absolute',
        //                         top: '1.56rem',
        //                         left: '9.7em',
        //                         width: '35%',
        //                         height: '75%'
        //                     }}
        //                 >
        //                     <div className="hex-1 flex  flex-col items-center">
        //                         <img
        //                             className="service-img"
        //                             src={ServicesWebDevelopment}
        //                             alt="img"
        //                             style={{ position: 'absolute', top: '3.12rem' }}
        //                         />

        //                         <h3
        //                             className="text-font-heading service-heading text-white font-medium feature-title"
        //                             style={{ position: 'absolute', top: '8.75rem' }}
        //                         >
        //                             Product Development
        //                         </h3>
        //                         <p
        //                             className="mt-2 service-text text-white feature-text"
        //                             style={{ position: 'absolute', top: '10.6rem' }}
        //                         >
        //                             Talented product people, developers and designers - we have the rightteam to create
        //                             exceptional web or mobile applications for you,{' '}
        //                         </p>
        //                     </div>
        //                 </div>
        //                 <div
        //                     className="hexagon right "
        //                     style={{
        //                         position: 'absolute',
        //                         top: '1.56rem',
        //                         right: '9.7rem',
        //                         width: '35%',
        //                         height: '75%'
        //                     }}
        //                 >
        //                     <div className="hex-2 flex  flex-col items-center">
        //                         <img
        //                             className="service-img"
        //                             src={ServicesMobileDevelopment}
        //                             alt="img"
        //                             style={{ position: 'absolute', top: '3.12rem' }}
        //                         />

        //                         <h3
        //                             className="text-font-heading service-heading text-white font-medium feature-title"
        //                             style={{ position: 'absolute', top: '8.75rem' }}
        //                         >
        //                             Dedicated Tech Teams
        //                         </h3>
        //                         <p
        //                             className="mt-2 service-text text-white feature-text"
        //                             style={{ position: 'absolute', top: '10.6rem' }}
        //                         >
        //                             Working accross traditonal and emmerging technologies and with a keen eye for
        //                             creating seamless applications, we work with dedicated engineers who can work
        //                             alongside your team however you need to give your flexibility
        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div
        //                 className="hex-layer-2"
        //                 style={{
        //                     position: 'relative',
        //                     top: '-10.6rem',
        //                     height: '28.75rem'
        //                 }}
        //             >
        //                 <div
        //                     className="hexagon left2 "
        //                     style={{ position: 'absolute', top: '1.56rem', width: '35%', height: '75%' }}
        //                 >
        //                     <div className="hex-3 flex  flex-col items-center">
        //                         <img
        //                             className="service-img"
        //                             src={ServicesWebDevelopment}
        //                             alt="img"
        //                             style={{ position: 'absolute', top: '3.12rem' }}
        //                         />

        //                         <h3
        //                             className="text-font-heading service-heading text-white font-medium feature-title"
        //                             style={{ position: 'absolute', top: '8.75rem' }}
        //                         >
        //                             Talent Scouting
        //                         </h3>
        //                         <p
        //                             className="mt-2 service-text text-white feature-text"
        //                             style={{ position: 'absolute', top: '10.6rem' }}
        //                         >
        //                             Whatever skills your require, we help you find the right people for the job, with
        //                             our network of highly skilled developers and designers ready to work with you.
        //                         </p>
        //                     </div>
        //                 </div>
        //                 <div
        //                     className="hexagon middle "
        //                     style={{
        //                         position: 'absolute',
        //                         top: '1.56rem',
        //                         left: '20.31rem',
        //                         width: '35%',
        //                         height: '75%'
        //                     }}
        //                 >
        //                     <div className="hex-4 flex  flex-col items-center">
        //                         <h3
        //                             className="agile-text text-font-heading service-heading text-white font-medium feature-title"
        //                             style={{ position: 'absolute', top: '6.25rem' }}
        //                         >
        //                             AGILE DEVELOPMENT
        //                         </h3>
        //                         <img
        //                             className="agile-img"
        //                             src={AgileDevlopment}
        //                             alt="img"
        //                             style={{ position: 'absolute', top: '10rem' }}
        //                         />
        //                     </div>
        //                 </div>
        //                 <div
        //                     className="hexagon right2 "
        //                     style={{
        //                         position: 'absolute',
        //                         top: '1.56rem',
        //                         left: '40.62rem',
        //                         width: '35%',
        //                         height: '75%'
        //                     }}
        //                 >
        //                     <div className="hex-5 flex  flex-col items-center">
        //                         <img
        //                             className="service-img"
        //                             src={ServicesMobileDevelopment}
        //                             alt="img"
        //                             style={{ position: 'absolute', top: '3.12rem' }}
        //                         />

        //                         <h3
        //                             className="text-font-heading service-heading text-white font-medium feature-title"
        //                             style={{ position: 'absolute', top: '8.75rem' }}
        //                         >
        //                             Strategizing
        //                         </h3>
        //                         <p
        //                             className="mt-2 service-text text-white feature-text"
        //                             style={{ position: 'absolute', top: '10.6rem' }}
        //                         >
        //                             Together, we can help you take your fledgling idea and set it up for success, taking
        //                             stick of the competitive landscape and defining the value you want to deliver.
        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className="lg:w-2/3 lg:hidden mx-auto">
        //             <div className="mt-12 text-center">
        //                 <GreenButton>
        //                     <a href="mailto:hello@digitaltrons.com">{services.buttonText}</a>
        //                 </GreenButton>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Service
