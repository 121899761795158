import PropTypes from 'prop-types'

import PageFooter from './PageFooter'

import { useEffect, useLayoutEffect } from 'react'
import PageHeader from './PageHeader'

const PageLayout = ({ children }) => {
    useEffect(() => {
        document.querySelector('#root').scrollTo(0, 0)
    })

    return (
        <div className="h-full">
            <PageHeader />
            <div className="child">{children}</div>
            <PageFooter />
        </div>
    )
}

PageLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default PageLayout
