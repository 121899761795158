import {
    AgileMethod,
    ArrowCircle,
    DataSecurity,
    DottedChat,
    Expert,
    IdeaBulb,
    OverlappedCircles,
    WhyUsPageTop
} from 'assets/img'

export default {
    pageTop: {
        title: 'Delivering business goals through software development',
        title2: 'Doing Digital for Start-Ups and Small Businesses',
        text: 'We delight in supporting entrepreneurs and companies bring their ideas to digital life.',
        buttonText: 'GET IN TOUCH',
        imgUrl: WhyUsPageTop,
        alt: 'Why Us Page Top'
    },
    blackSection: {
        title: {
            large: <>Your Success is Our Success</>,
            small: 'Striving to produce exceptional digital solutions to grow your business.'
        },

        text: 'The Digital Trons team comprises talented designers, experienced developers, and passionate product people. We love nothing more than helping our customers bring their ideas to life. Let us know how we can help you!',
        buttonText: "Let's Talks"
    },
    mission: {
        title: 'OUR MISSION',
        features: [
            {
                imgUrl: ArrowCircle,
                alt: 'Arrow Circle',
                title: 'Agility',
                text: 'We keep our approach agile to adapt to your needs.'
            },
            {
                imgUrl: IdeaBulb,
                alt: 'Idea Bulb',
                title: 'User-Centric',
                text: 'To ensure we deliver the best results for your users.'
            },
            {
                imgUrl: OverlappedCircles,
                alt: 'Overlapped Circles',
                title: 'Transparent',
                text: `No surprises – we're transparent on price and on process.`
            },
            {
                imgUrl: Expert,
                alt: 'Expert',
                title: 'Techinically Brilliant',
                text: 'Up-to-date across all technologies to build the best solutions.'
            },
            {
                imgUrl: DottedChat,
                alt: 'Dotted Chat',
                title: 'Clear Communication',
                text: 'We explain things clear at every step of what we do.'
            },
            {
                imgUrl: DataSecurity,
                alt: 'Data Security',
                title: 'Safe & Secure',
                text: 'Your ideas and IP are safeguarded as a priority.'
            }
        ]
    },
    agile: {
        top: {
            imgUrl: AgileMethod,
            alt: 'Agile Method',
            title: 'Agile for the Win',
            text: ` We adopt an Agile approach to product development to ensure we keep flexible and continuously iterate on our work to incorporate your feedback, ensuring you'll be thrilled with the final solution.`
        },
        bottom: {
            features: [
                {
                    title: 'Scope and Plan',
                    text: 'We help take your idea and flesh it out, defining what will be needed from a product-perspective, how this might develop in the future, and what we need to do to achieve this.'
                },
                {
                    title: 'Analysis and Design',
                    text: 'From how we visually communicate your brand to how your users will interact with each screen, we design the user flow and create wireframes, prototypes and mockups.'
                },
                {
                    title: 'Implementation',
                    text: 'Our developers build your product, giving the designs life and using the most suitable technologies to ensure a seamless end-user experience, followed by rigorous testing to squash any bugs.'
                },
                {
                    title: 'Deliver',
                    text: 'We help with the deployment and launch of your product – and will be there to celebrate this important milestone with you!'
                }
            ]
        }
    },
    greenSection: {
        title: <>We&apos;re ready to get to work. Contact us to tell us more about how we can help you.</>,
        buttonText: 'SCHEDULE A SESSION'
    }
}
