import clsx from 'clsx'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { PageLayout } from '../../components'
import { LandingPageText } from 'assets/text'
import { GreenButton } from 'components/atoms'
import { ContactUs } from 'components/atoms'
import { BookNowButton } from 'components/atoms'
import cloud from './../../assets/img/landing/cloud.png'
import cloudLeft from './../../assets/img/landing/cloudLeft.png'

import cloudRight from './../../assets/img/landing/cloudRight.png'

import Service from '../Services/Service'
import OurWorks from '../Work/OurWorks'
import BookSession from '../../assets/img/landing/BookSession.svg'
import Line from '../../assets/img/landing/Line.svg'
import Client1 from '../../assets/img/landing/Client1.svg'
import Client2 from '../../assets/img/landing/Client2.svg'

import Client3 from '../../assets/img/landing/Client3.svg'
import aboutimg1 from '../../assets/img/landing/aboutimg1.jpg'

import aboutimg2 from '../../assets/img/landing/aboutimg2.svg'

import aboutimg3 from '../../assets/img/landing/aboutimg3.jpg'

import aboutimg4 from '../../assets/img/landing/aboutimg4.svg'

import cloudNetwork from '../../assets/img/landing/cloudNetwork.svg'

import dotLine from '../../assets/img/landing/dotLine.svg'
import howwedoimg from '../../assets/img/landing/howwedoimg.svg'
import Fade from 'react-reveal/Fade'
import './Landing.scss'
const Landing = () => {
    const { pageTop, whyUs, howWeDo, services, process, bookSession, aboutUs, clients, clientReview } = LandingPageText
    const history = useHistory()

    return (
        <PageLayout>
            <Helmet>
                <title>DIGITAL TRONS | Atlanta&#039;s Top Custom Software Development Company</title>
            </Helmet>
            <div className="landing" id="landingPage">
                <div className="page-top relative flex  block-black">
                    <div className="content ">
                        <div className="head flex flex-col items-start">
                            <Fade bottom>
                                <h1 className="text1 text-font-heading">{pageTop.heading.top}</h1>
                                <h1 className="text2 text-font-heading">{pageTop.heading.bottom}</h1>
                            </Fade>
                            <div className="contact-us flex items-center justify-center z-20 lg:block interactiion text-center p-2 ">
                                <a href="#contact">
                                    <GreenButton style={{ width: '200px', fontSize: '18px' }}>CONTACT US</GreenButton>
                                </a>
                            </div>
                        </div>

                        <div className="cloud-div relative flex flex-row">
                            <img
                                className="cloudLeft"
                                src={cloudLeft}
                                alt="cloud"
                                style={{ position: 'relative', width: '150px', height: '50px' }}
                            />

                            <img className="cloud" src={cloud} alt="cloud" />
                            <img
                                className="cloudRight"
                                src={cloudRight}
                                alt="cloud"
                                style={{ position: 'relative', width: '150px', height: '50px' }}
                            />
                        </div>
                        <div
                            className="contact-us-mobile flex items-center justify-center lg:block interactiion text-center p-2 lg:col-span-2"
                            style={{ position: 'relative', top: '-20px' }}
                        >
                            <a href="#contact">
                                <GreenButton style={{ width: '200px', fontSize: '18px' }}>CONTACT US</GreenButton>
                            </a>
                        </div>
                    </div>
                    <div className="wave absolute h-full w-full top-12 left-0 flex justify-center items-center">
                        <img
                            className="w-full"
                            style={{ position: 'absolute' }}
                            src={pageTop.bgImage[1].imgUrl}
                            alt={pageTop.bgImage[0].alt}
                        />
                    </div>
                </div>
                <div className="whyUs-block block-black " id="whyUs">
                    <div className="whyUs-block-content flex flex-col justify-center pt-20">
                        <div className="heading-div">
                            <p className="text-font-heading text-white text-center text-3xl">{whyUs.text.heading}</p>
                        </div>
                        <div className="whyUs-content flex flex-row gap-x-4 gap-y-12 mt-12">
                            {whyUs.features.map(({ imgUrl, title, text, alt }) => (
                                <div className="md:p-8 pt-0 lg:p-4 flex flex-col items-center" key={Math.random()}>
                                    <div className="flex" style={{ width: '90px', height: '90px' }}>
                                        {imgUrl}
                                    </div>
                                    <h3 className="text-font-heading text-white text-center font-bold  text-2xl mt-3 mb-3">
                                        {title}
                                    </h3>
                                    <p className=" sm:w-2/3 md:w-full text-center text-white feature-text">{text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className=" pt-20 pb-20 pl-5 pr-5  block-black" id="aboutus">
                    <div className="aboutUs-contents flex flex-col ">
                        <div className="aboutUs-block flex flex-row">
                            <Fade left>
                                <div className="about-img ">
                                    <img className="aboutimg1" src={aboutimg1} alt="about-img-1" />
                                </div>
                            </Fade>

                            <div className="relative aboutUs-content flex flex-col  ">
                                <Fade bottom>
                                    <h2 className="aboutUs-text-heading text-font-heading text-white text-3xl">
                                        Electrify software Development
                                    </h2>
                                    <p className="aboutUs-text text-white mt-6 ">
                                        {' '}
                                        Digital Trons offers complete technology solutions with perfection for startups,
                                        entrepreneurs and growing businesses in Atlanta, GA and around the world. We
                                        bring a blend of unique skill sets, passion and professionalism to help our
                                        partners get measurable results. Our goal is to provide high quality
                                        result-driven work through seamless, exceptional execution.
                                    </p>
                                </Fade>
                            </div>
                        </div>
                        <div className="aboutUs-block aboutUs-block-2 flex flex-row mt-20">
                            {' '}
                            <div className="relative aboutUs-content flex flex-col ">
                                <Fade bottom>
                                    <h2 className="aboutUs-text-heading text-font-heading text-white text-3xl">
                                        Digital. Delivered.{' '}
                                    </h2>
                                    <p className="aboutUs-text text-white mt-6 ">
                                        Ideas are easy to make but difficult to implement and that&apos;s where we shine
                                        with unique strategies and a clear roadmap. Through our technology partnership
                                        and development resources, we strive to produce exceptional products to grow
                                        your business.
                                    </p>
                                </Fade>
                            </div>
                            <Fade right>
                                <div className="about-img">
                                    <img className="aboutimg3 " src={aboutimg3} alt="about-img-3" />
                                </div>
                            </Fade>
                        </div>
                        <div className="about-info flex flex-row justify-around mt-20">
                            <div className="about-info-detail-top">
                                <div className="about-info-detail flex flex-col items-center">
                                    <h2
                                        className="text-font-heading"
                                        style={{
                                            fontSize: '33.2511px',
                                            lineHeight: '51px',
                                            textAlign: 'center',

                                            /* Primary/ Blue 2 */

                                            color: '#12C4B2'
                                        }}
                                    >
                                        20+
                                    </h2>
                                    <img src={dotLine} alt="dotLine" style={{ width: '100px' }} />

                                    <p className="text-white">Happy Clients</p>
                                </div>
                                <div className="about-info-detail flex flex-col items-center">
                                    <h2
                                        className="text-font-heading"
                                        style={{
                                            fontSize: '33.2511px',
                                            lineHeight: '51px',
                                            textAlign: 'center',

                                            /* Primary/ Blue 2 */

                                            color: '#12C4B2'
                                        }}
                                    >
                                        40+
                                    </h2>
                                    <img src={dotLine} alt="dotLine" style={{ width: '100px' }} />

                                    <p className="text-white">Ambitious Projects</p>
                                </div>
                            </div>

                            <div className="about-info-detail-bottom">
                                <div className="about-info-detail flex flex-col items-center">
                                    <h2
                                        className="text-font-heading"
                                        style={{
                                            fontSize: '33.2511px',
                                            lineHeight: '51px',
                                            textAlign: 'center',

                                            /* Primary/ Blue 2 */

                                            color: '#12C4B2'
                                        }}
                                    >
                                        99%
                                    </h2>
                                    <img src={dotLine} alt="dotLine" style={{ width: '100px' }} />

                                    <p className="text-white">Positive Feedback</p>
                                </div>

                                <div className="about-info-detail flex flex-col items-center">
                                    <h2
                                        className="text-font-heading"
                                        style={{
                                            fontSize: '33.2511px',
                                            lineHeight: '51px',
                                            textAlign: 'center',

                                            /* Primary/ Blue 2 */

                                            color: '#12C4B2'
                                        }}
                                    >
                                        5 YRS
                                    </h2>
                                    <img src={dotLine} alt="dotLine" style={{ width: '100px' }} />

                                    <p className="text-white">Company Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="relative howdowedo"
                    style={{ opacity: '1', zIndex: '2', backgroundColor: 'rgb(8,39,50)' }}
                >
                    <div style={{ position: 'absolute', width: '100%', top: '0', height: '100%' }}>
                        <img src={howwedoimg} alt="howwedoimg" style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div className="howdowedoinner relative flex flex-row " style={{ zIndex: '20' }}>
                        <div className="howdowedocontent">
                            <h2 className="howdowedotexthead text-font-heading font-bold  text-3xl">
                                {howWeDo.heading}
                            </h2>
                            <p className="howdowedotext text-white mt-8">{howWeDo.text}</p>
                        </div>
                        {/* <div className="cloudNetwork">
                            <img className="cloudNetworkimg" src={cloudNetwork} alt="cloudNetworkimg" />
                        </div> */}
                    </div>
                    {/* <div className="relative  flex justify-center mt-20 z-20">
                        <GreenButton
                            onClick={() =>
                                window.open('https://calendly.com/krunal-thakkar/free-consultation', '_blank').focus()
                            }
                            style={{ width: '200px', fontSize: '20px' }}
                        >
                            Let&apos;s Talk
                        </GreenButton>
                    </div> */}
                    <div
                        className="curved-block "
                        style={{ zIndex: '10', bottom: '-25%', backgroundColor: 'rgb(8,39,50)' }}
                    />
                </div>
                <Service />

                {/* <div className="block block-padded mt-10" id="services">
                    <div className="block-content">
                        <div className="grid grid-cols-5">
                            <div className="col-span-5 md:col-span-2 text-center md:text-left flex items-center justify-center">
                                <h3 className="text-font-heading section-sub-heading">
                                    <ShadowText
                                        className="font-bold text-2xl"
                                        bgColor="bgcolor-yellow"
                                        bgOpacity="opacity-20"
                                    >
                                        {services.heading.main}
                                    </ShadowText>
                                </h3>
                            </div>
                            <div className="hidden md:block md:col-span-3">
                                <p className="text-font-heading text-3xl">{services.heading.sub}</p>
                                <p className=" mt-2 section-description">{services.text}</p>
                            </div>
                        </div>
                        <div>
                            <div className="grid gap-16 grid-cols-1 md:grid-cols-2 mt-12">
                                {services.features.map(({ imgUrl, title, text, alt }) => (
                                    <div key={Math.random()} className="flex gap-3 lg:p-4">
                                        <div
                                            className="service-card  p-4 flex items-center  "
                                            style={{ minHeight: 100, minWidth: 100 }}
                                        >
                                            <img src={imgUrl} alt={alt} />
                                        </div>
                                        <div>
                                            <h3 className="text-font-heading font-medium feature-title">{title}</h3>
                                            <p className="mt-2 feature-text">{text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="lg:w-2/3 mx-auto">
                            <div className="mt-12 text-center">
                                <GreenButton>
                                    <a href="mailto:hello@digitaltrons.com">{services.buttonText}</a>
                                </GreenButton>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="block-black bookSession-outer-block  text-center" style={{ opacity: '0.97' }}>
                    <div className="bookSession-inner-block  mx-auto">
                        <div className="bookSession-content flex flex-col">
                            <h2 className=" text-white bookSession-header-text text-font-heading text-3xl">
                                Technically Brilliant
                            </h2>
                            <p className=" text-white   bookSession-text mt-8 mx-auto" style={{ width: '80%' }}>
                                {bookSession.text}
                            </p>
                        </div>
                        <div className="mt-10">
                            <a href="#contact">
                                <GreenButton style={{ width: '200px', fontSize: '18px' }}>
                                    {bookSession.buttonText}
                                </GreenButton>
                            </a>
                        </div>
                    </div>
                </div>

                <OurWorks />

                {/* <div className="block block-padded flex flex-col justify-center align-center">
                    <div className="grid grid-cols-5">
                        <div className="col-span-5 md:col-span-2 flex items-center justify-center font-bold section-sub-heading">
                            <ShadowText
                                className="text-font-heading text-2xl"
                                bgOpacity="opacity-20"
                                bgColor="bgcolor-yellow"
                            >
                                {clients.heading.main}
                            </ShadowText>
                        </div>
                        <div className="hidden md:block md:col-span-3">
                            <p className="text-font-heading text-3xl">{clients.title}</p>
                            <p className=" mt-2 section-description">{clients.description}</p>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className="mt-16">
                            <div className="flex overflow-x-auto flex-wrap gap-5 justify-center align-center">
                                {clients.features.map(({ imgUrl, alt }) => (
                                    <div key={Math.random()} className="flex w-full md:w-48 justify-center mr-12 mb-12">
                                        <img
                                            style={{
                                                maxHeight: '100px',
                                                minHeight: '100px',
                                                width: 'auto',
                                                marginLeft: '20px',
                                                paddingLeft: '30px'
                                            }}
                                            src={imgUrl}
                                            alt={alt}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="clientReview-outer block block-black " style={{ opacity: '0.97' }}>
                    <div className="clientReview-inner  flex flex-col items-center">
                        <div className="flex flex-row items-center">
                            <h1 className="clientReview-title text-font-heading text-white font-bold highlighted-text text-3xl">
                                {clientReview.title}
                            </h1>
                        </div>
                        <div className="mt-8 clientReview-ic flex flex-row">
                            {/* {clientReview.reviews.map(({ id, name, location, review }, index) => (
                                    <React.Fragment key={index}>
                                        {index > 0 && (
                                            <div className="w-10/12 mx-auto my-8 bg-gray-100 border-t opacity-30" />
                                        )}
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 md:col-span-1 flex flex-col gap-1 ">
                                                <span className="text-black font-extralight highlight-sub-text">
                                                    {id}
                                                </span>
                                                <span className="text-font-heading text-black section-heading">
                                                    {name}
                                                </span>
                                                <span className=" text-black font-extralight section-description">
                                                    {location}
                                                </span>
                                            </div>
                                            <div className="col-span-3 md:col-span-2 flex justify-center align-center">
                                                <span className=" section-description">{review}</span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))} */}
                            <Fade left>
                                <div className="client-img">
                                    <img id="client-i" src={Client1} alt="client-img" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="clientReview  flex flex-col items-center">
                                    <div className="col-span-3 md:col-span-1 flex flex-col gap-1 ">
                                        <span className="clientName text-font-heading text-center text-white section-heading">
                                            {clientReview.review1.name}
                                        </span>
                                        <span className="clientLocation text-center text-font-heading text-white font-extralight section-description">
                                            {clientReview.review1.location}
                                        </span>
                                    </div>
                                    <div className="col-span-3 md:col-span-2 mt-2 flex justify-center align-center">
                                        <span className="review text-white text-center section-description">
                                            {clientReview.review1.review}
                                        </span>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="mt-8 clientReview-ic ic-m flex flex-row" style={{ opacity: '0.97' }}>
                            <Fade left>
                                <div className="clientReview-m flex flex-col items-center">
                                    <div className="col-span-3 md:col-span-1 flex flex-col gap-1 ">
                                        <span className="clientName text-font-heading text-center  text-white section-heading">
                                            {clientReview.review2.name}
                                        </span>
                                        <span className="clientLocation text-font-heading text-center text-white font-extralight section-description">
                                            {clientReview.review2.location}
                                        </span>
                                    </div>
                                    <div className="col-span-3 md:col-span-2 mt-2 flex justify-center align-center">
                                        <span className="review text-white text-center section-description">
                                            {clientReview.review2.review}
                                        </span>
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="client-img">
                                    <img id="client-i" src={Client2} alt="client-img" />
                                </div>
                            </Fade>
                        </div>
                        <div className="mt-8 clientReview-ic  flex flex-row">
                            <Fade left>
                                <div className="client-img">
                                    <img id="client-i" src={Client3} alt="client-img" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="clientReview flex flex-col items-center">
                                    <div className="col-span-3 md:col-span-1 flex flex-col gap-1 ">
                                        <span className="clientName text-center text-font-heading text-white section-heading">
                                            {clientReview.review3.name}
                                        </span>
                                        <span className="clientLocation text-center text-font-heading text-white font-extralight section-description">
                                            {clientReview.review3.location}
                                        </span>
                                    </div>
                                    <div className="col-span-3 md:col-span-2 mt-2 flex justify-center align-center">
                                        <span className="review text-white text-center section-description">
                                            {clientReview.review3.review}
                                        </span>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
                <ContactUs />
            </div>
        </PageLayout>
    )
}

export default Landing
