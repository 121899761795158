/* eslint-disable react/prop-types */
import clsx from 'clsx'
import { NavLink, useHistory } from 'react-router-dom'
import { ArrowRight } from 'assets/img'
import { onButtonClick } from './layout/NavLinks'
import contact from '../assets/img/landing/contact.svg'
import location from '../assets/img/landing/location.svg'
import { useState } from 'react'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import loading from '../assets/img/landing/loading.gif'
import submitFormAnimation from '../assets/img/landing/submitFormAnimation.gif'
import './atoms.scss'
export const ShadowedText = ({ children, className, bgColor, bgOpacity }) => (
    <span className="relative page">
        <span className={clsx('p relative z-10 ', className)}>{children}</span>
        <span
            className={clsx(' absolute bottom-0 left-0 w-full h-2 z-0 bg-white opacity-50 dash', bgColor, bgOpacity)}
        />
    </span>
)
export const ShadowText = ({ children, className, inactive, bgColor, bgOpacity }) => (
    <span className="relative">
        <span className={clsx('relative z-10', className)}>{children}</span>
        <span
            className={clsx(
                inactive ? 'hidden' : 'inline-block',
                'absolute bottom-0 left-0 w-full h-2 z-0 bg-white opacity-50',
                bgColor,
                bgOpacity
            )}
        />
    </span>
)

export const Divider = ({ className }) => (
    <div className={clsx('w-10/12 mx-auto my-8 bg-gray-100 border-t', className)} />
)

export const Button = ({ children, className, ...rest }) => (
    <button className={clsx('px-8 py-2 font-semibold hover:shadow-lg', className)} {...rest}>
        {children}
    </button>
)
export const GreenButton = ({ children, className, style, ...rest }) => (
    <Button
        className={clsx('text-white', className)}
        style={{
            background: ' linear-gradient(90deg, #00C2BC 0%, #12C4B2 19.9%, #42CC9B 59.9%, #5AD090 77.9%)',

            ...style
        }}
        {...rest}
    >
        {children}
    </Button>
)
export const BlackButton = ({ children, className, ...rest }) => (
    <Button className={clsx('bg-black text-white', className)} {...rest}>
        {children}
    </Button>
)
export const BorderButton = ({ children, className, ...rest }) => (
    <Button className={clsx('border', className)} {...rest}>
        {children}
    </Button>
)

export const BookNowButton = () => {
    return (
        <GreenButton
            onClick={() => window.open('https://calendly.com/krunal-thakkar/free-consultation', '_blank').focus()}
            style={{ width: '200px' }}
        >
            <span>CONSULT WITH US</span>
        </GreenButton>
    )
}

export const FeelingInspired = () => {
    const history = useHistory()

    return (
        <div className="block-black block-padded">
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-center text-center md:text-left">
                <div className="flex-1 text-center lg:text-left">
                    <h2 className="text-white mb-0 highlighted-text font-bold mb-5">Feeling Inspired?</h2>
                    <p className="text-white text-base text-lg highlighted-sub-text">
                        Let&apos;s discuss how Digital Trons Software can help with your projects development needs!
                    </p>
                </div>
                <div className="mt-8 lg:mt-0 flex justify-center lg:justify-end">
                    <GreenButton
                        onClick={() =>
                            window.open('https://calendly.com/krunal-thakkar/free-consultation', '_blank').focus()
                        }
                        className="px-16"
                    >
                        LET&apos;S TALK
                    </GreenButton>
                </div>
            </div>
        </div>
    )
}

export const ContactUs = () => {
    const [enteredName, setName] = useState('')
    const [enteredCompany, setCompany] = useState('')
    const [load, setLoad] = useState(false)

    const [enteredEmail, setEmail] = useState('')
    const [enteredAboutProject, setAboutProject] = useState('')
    const [errMessage, setErrMessage] = useState({ state: false, msg: null })

    const checkForm = () => {
        if (
            enteredName.length > 0 &&
            enteredCompany.length > 0 &&
            enteredEmail.length > 0 &&
            enteredAboutProject.length > 0
        ) {
            if (enteredEmail.includes('@')) {
                setErrMessage({
                    state: false,
                    msg: null
                })
                return true
            } else {
                setErrMessage({
                    state: true,
                    msg: 'Please enter a valid email'
                })
                return false
            }
        } else {
            setErrMessage({
                state: true,
                msg: 'Please fill all the fields'
            })
            return false
        }
    }
    const submitForm = async (e) => {
        let inputs = document.querySelectorAll('input')
        let textareas = document.querySelectorAll('textarea')
        let sAF = document.getElementById('submitAnimation')
        e.preventDefault()
        const state = checkForm()
        if (state) {
            setLoad(true)
            const a = await axios.post('https://q6jssg07vd.execute-api.us-east-1.amazonaws.com/dev/dt/contact-us', {
                email: enteredEmail,
                fullName: enteredName,
                company: enteredCompany,
                idea: enteredAboutProject
            })
            inputs.forEach((input) => (input.value = ''))
            textareas.forEach((textarea) => (textarea.value = ''))
            sAF.style.display = 'block'
            setTimeout(() => {
                sAF.style.display = 'none'
            }, 4000)
            setLoad(false)

            console.log(a)
        }
    }
    return (
        <div id="contact" className="block-black">
            <div
                className="contact-b p-10 flex flex-row "
                style={{
                    background:
                        'linear-gradient(168.72deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0) 105.75%)'
                }}
            >
                <div className="contact-t  flex flex-col">
                    <div>
                        <h2 className="text-font-heading contact-us-heading text-white text-3xl">Contact Us</h2>
                    </div>
                    <form action method="post" autoComplete="off">
                        <div className="mt-4">
                            <label className="text-white ">
                                <div className="label">Full Name</div>
                                <div className="input">
                                    <input type="text" required onChange={(e) => setName(e.target.value)} />
                                </div>
                            </label>
                        </div>

                        <div className="mt-4">
                            <label className=" text-white">
                                <div className="label">Company</div>
                                <div className="input">
                                    <input type="text" onChange={(e) => setCompany(e.target.value)} required />
                                </div>
                            </label>
                        </div>

                        <div className="mt-4">
                            <label className=" text-white">
                                <div className="label">Email Address</div>
                                <div className="input">
                                    <input type="email" onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                            </label>
                        </div>

                        <div className="mt-4">
                            <label className=" text-white">
                                <div className="label"> About Project</div>

                                <div className="input-textarea">
                                    <textarea
                                        className="textarea"
                                        rows="4"
                                        cols="50"
                                        name="comment"
                                        form="usrform"
                                        onChange={(e) => setAboutProject(e.target.value)}
                                    />
                                </div>
                            </label>
                        </div>
                        {errMessage.state ? (
                            <div className="signup-cont-2 signup-err-cont ">
                                <Alert
                                    severity="error"
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginTop: '5px',
                                        width: '100%'
                                    }}
                                >
                                    {errMessage.msg}
                                </Alert>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="flex justify-center items-center mt-4">
                            {load == true && (
                                <img
                                    src={loading}
                                    alt="loading"
                                    style={{ width: '25px', height: '25px', marginRight: '5px' }}
                                />
                            )}
                            <button
                                disabled={load}
                                name="submit"
                                type="submit"
                                onClick={(e) => submitForm(e)}
                                style={{
                                    background:
                                        ' linear-gradient(90deg, #00C2BC 0%, #12C4B2 19.9%, #42CC9B 59.9%, #5AD090 77.9%)',
                                    width: '200px',
                                    cursor: 'pointer'
                                }}
                                className="login-btn text-white text-font-heading z-20"
                            >
                                SUBMIT REQUEST
                            </button>
                            <div id="submitAnimation">
                                <img
                                    src={submitFormAnimation}
                                    alt="submitFormAnimation"
                                    style={{ width: '100px', height: '50px', marginLeft: '5px' }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="contact-i flex flex-col-reverse">
                    <img className="contact-img" src={contact} alt="contact" />
                </div>
            </div>
        </div>
    )
}
