import { WorkPageText } from 'assets/text'

import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import './OurWorks.scss'

import Cards from './Cards'

const Work = () => {
    // const { pageTop, request, clients, projects } = WorkPageText
    const { pageTop, projects } = WorkPageText

    const history = useHistory()
    return (
        <>
            <div className="block block-black  work" id="works">
                <div className="work">
                    <div className="work-h flex flex-col">
                        <h3 className="works-head text-font-heading text-center text-white section-sub-heading text-3xl">
                            {pageTop.title}
                        </h3>
                        <p className=" works-text mt-2 text-center text-white section-description">{pageTop.text}</p>
                    </div>

                    <div className="pt-10" style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <div className="project-list">
                                <Cards />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Work
