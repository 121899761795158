import clsx from 'clsx'
import { ShadowedText } from 'components/atoms'
import { GreenButton } from 'components/atoms'
import { useState } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'
import { BookNowButton } from 'components/atoms'
import './Navlinks.scss'
export const navLinks = [
    {
        id: 1,
        text: 'Why Us',
        route: '#whyUs'
    },
    {
        id: 2,
        text: 'About Us',
        route: '#aboutus'
    },
    {
        id: 3,
        text: 'Services',
        route: '#services'
    },

    {
        id: 4,
        text: 'Our Work',
        route: '#works'
    },

    {
        id: 5,
        text: 'Contact Us',
        route: '#contact'
    }
]

export const onButtonClick = (id, history, pageSection) => {
    const routeLink = navLinks.filter((routeLink) => routeLink.id === id)
    history.push(routeLink[0].route, pageSection || '')
}

export const getRoute = (id) => {
    const routeLink = navLinks.filter((routeLink) => routeLink.id === id)
    return routeLink[0].route
}

// const NavLinks = () => {
//     const [activeNavLink, setActiveNavLink] = useState('')
//     // $('html,body').animate({
//     //     scrollTop: $(hash).offset().top
//     // })

//     return (
//         <div className="navlink flex justify-center w-full" style={{ fontWeight: 'bold' }}>
//             <div style={{ width: '100%' }}>
//                 <div className="flex flex-1 flex-col lg:flex-row justify-center items-center w-full">
//                     {navLinks.map(({ text, route }) => (
//                         <a
//                             href={route}
//                             key={Math.random()}
//                             className={clsx(
//                                 'navlink-block h-full block lg:p-0 border-b lg:border-none flex items-center justify-center text-light'
//                             )}
//                         >
//                             <ShadowedText bgColor="bgcolor-yellow" className="section-heading-large n-link-text">
//                                 {text}
//                             </ShadowedText>
//                         </a>
//                     ))}
//                     {/* <div className="z-20 h-full block p-2 pt-0 lg:p-0 border-b lg:border-none flex items-center justify-center">
//                         <div className="interactiion flex justify-center pt-3 pb-3">
//                             <GreenButton style={{ width: '200px' }}>CONSULT WITH US</GreenButton>
//                         </div>
//                     </div> */}
//                     <div className="contact-us-mobile  flex items-center justify-center lg:block interactiion text-center  ml-7 lg:col-span-2">
//                         <a href="https://calendly.com/krunal-thakkar/free-consultation" target="blank">
//                             <GreenButton style={{ width: '200px', fontSize: '18px' }}>BOOK A SESSION</GreenButton>
//                         </a>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default NavLinks
