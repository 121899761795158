import React from 'react'
import { FeelingInspired, PageLayout } from 'components'
import { ShadowedText } from 'components/atoms'
import { GreenButton } from 'components/atoms'
import { BlackButton } from 'components/atoms'
import { onButtonClick } from 'components/layout/NavLinks'
import appstore from '../../assets/img/work/appstore.svg'
import playstore from '../../assets/img/work/playstore.svg'
import iphone from '../../assets/img/work/iphone.png'
import flylady from '../../assets/img/work/flylady.png'
import flourish from '../../assets/img/work/flourish.png'
import vaultlogik from '../../assets/img/work/vaultlogik.svg'
import { WorkPageText } from 'assets/text'
import './OurWorks.scss'
// import AOS from 'aos'
// import $ from 'jquery'
import Fade from 'react-reveal/Fade'
import { NavLink } from 'react-router-dom'
function Cards() {
    const { pageTop, projects } = WorkPageText
    // $(window).on('scroll', function () {
    //     AOS.init()
    // })
    // $(window).on('load', function () {
    //     AOS.refresh()
    // })

    return (
        <>
            <Fade left>
                <div id="work-list" className="work-list flex flex-row mb-5 pl-7 pr-7 pt-7">
                    <div
                        className="mt-6 ml-6 mr-6 flex  items-center 
                       md:order-2 iphone-o"
                    >
                        <img className="iphone-i w-full project-i project-i-2 " src={iphone} alt="iphone" />
                    </div>
                    <div className="project-work m-6 flex flex-col items-start">
                        <div
                            className="project-work-text flex flex-col 
           "
                        >
                            <h4 className="work-title text-font-heading  font-bold text-white">UBIQUITOUS</h4>
                            <p className="work-title-text mt-5 section-description text-white">
                                Ubiquitous is a marketplace that connects global brands and influencers. Ubiquitous has
                                access to thousands of creators and influencers across all demographics with over 2.5
                                billion in follower count.
                            </p>
                            <p className="work-title-text mt-5 section-description text-white">
                                Digital Trons is working closely with them to bring various web & mobile products to the
                                market and take a huge lead in this $100B+ industry in 5 years.
                            </p>
                        </div>
                        {/* <div className="download mt-7">
                            <h5 className="text-white">Download it on</h5>
                            <div className="download-img flex">
                                <img style={{ width: '40px' }} src={appstore} alt="app-store" />
                                <img style={{ width: '60px' }} src={playstore} alt="play-store" />
                            </div>
                        </div> */}
                        <div className="service-top-button z-10">
                            <div className="mt-5 ">
                                <GreenButton className="project-button" style={{ width: '200px', fontSize: '15px' }}>
                                    <a href={projects.href1} target="_blank" rel="noopener noreferrer">
                                        VISIT UBIQUITOUS
                                    </a>
                                </GreenButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            <Fade right>
                <div className="work-list flex flex-row mb-5 pl-7 pr-7 pt-7">
                    <div
                        className="mt-6 ml-6 mr-6  flex  items-center 
     flylady-o"
                    >
                        <img className="flylady-i w-full project-i project-i-1 " src={flylady} alt="flylady" />
                    </div>
                    <div className="project-work m-6 flex flex-col items-end">
                        <div
                            className="project-work-text flex flex-col  items-end
        "
                        >
                            <h4 className="work-title text-font-heading  font-bold text-white">FLY LADY</h4>
                            <p className="work-title-text mt-5 section-description text-white text-right">
                                Say hello to a decluttered home and organized life. FlyLadyPlus breaks the home down
                                into zones and gives you cleaning lists & a fun daily mission for one zone each week.
                            </p>
                            <p className="work-title-text mt-5 section-description text-white text-right">
                                Digital Trons overhaul 3+ years old FlyLadyPlus app with unique intuitive design. App
                                was built from scratch using hybrid technology for both iOS and Android users. App is
                                enjoying a 4900% increase in downloads and usage.{' '}
                            </p>
                        </div>
                        <div className="download mt-7 ">
                            <h5 className="text-white mb-2">Download it on</h5>
                            <div className="download-img flex flex-col gap-2">
                                <a
                                    href="https://apps.apple.com/us/app/flyladyplus"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ cursor: 'pointer', zIndex: '20' }}
                                >
                                    <img style={{ width: '150px' }} src={appstore} alt="app-store" />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.FlyLady.Premium"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ cursor: 'pointer', zIndex: '20' }}
                                >
                                    <img style={{ width: '150px' }} src={playstore} alt="play-store" />
                                </a>
                            </div>
                        </div>
                        {/* <div className="service-top-button z-10">
                            <div className="mt-5 ">
                                <GreenButton className="w-52 project-button">
                                    <a href={projects.href2}>Visit FlyLady</a>
                                </GreenButton>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Fade>
            <Fade left>
                <div className="work-list flex flex-row mb-5 pl-7 pr-7 pt-7">
                    <div
                        className="mt-6 ml-6 mr-6  flex  items-center 
    md:order-2 flourish-o"
                    >
                        <img className="flourish-i w-full project-i project-i-2 " src={flourish} alt="flourish" />
                    </div>
                    <div className="flourish-project-work m-6 flex flex-col items-start">
                        <div
                            className="project-work-text flex flex-col 
        "
                        >
                            <h4 className="work-title text-font-heading  font-bold text-white">FLOURISH SOFTWARE</h4>
                            <p className="work-title-text mt-5 section-description text-white">
                                Flourish is building the supply chain of the future with the cannabis industry through
                                better technology. It has been a leader in Seed to Sale supply chain software.
                            </p>
                            <p className="work-title-text mt-5 section-description text-white">
                                Digital Trons has been augmenting the Flourish team over 7 years in many tech verticals
                                including web, mobile, manual & automation QA and backend technologies.
                            </p>
                        </div>

                        <div className="service-top-button z-10">
                            <div className="mt-5 ">
                                <GreenButton className=" project-button" style={{ width: '200px', fontSize: '15px' }}>
                                    <a href={projects.href3} target="_blank" rel="noopener noreferrer">
                                        VISIT FLOURISH
                                    </a>
                                </GreenButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            <Fade right>
                <div className="work-list flex flex-row mb-5 pl-7 pr-7 pt-7">
                    <div
                        className="mt-6 ml-6 mr-6  flex  items-center 
    vaultlogik-o"
                    >
                        <img className="vaultlogik-i w-full project-i project-i-1" src={vaultlogik} alt="vaultlogik" />
                    </div>
                    <div className="vault-project-work m-6 flex flex-col  items-end">
                        <div
                            className="project-work-text flex flex-col  items-end
       "
                        >
                            <h4 className="work-title text-font-heading  font-bold text-white">VAULT LOGIK</h4>
                            <p className="work-title-text mt-5 section-description text-white text-right">
                                Vaultlogik is a Software as a Service (SaaS) product that offers top notch virtual data
                                room solutions with a wide range of customization and collaboration tools.
                            </p>
                            <p className="work-title-text mt-5 section-description text-white text-right">
                                Digital Trons worked very closely with the non-technical founders from the idea phase to
                                execution. Our team used best-in-class technologies to deliver an amazing product.
                            </p>
                        </div>

                        <div className="service-top-button z-10">
                            <div className="mt-5 ">
                                <GreenButton className=" project-button" style={{ width: '200px', fontSize: '15px' }}>
                                    <a href={projects.href4} target="_blank" rel="noopener noreferrer">
                                        VISIT VAULTLOGIK
                                    </a>
                                </GreenButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    )
}

export default Cards
