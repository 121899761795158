import {
    Approach,
    Capabilities,
    Cloud,
    MobileDev,
    MobileGame,
    QATesting,
    ServicesPageTop,
    UIUX,
    WebDevelopment,
    HTML5,
    CSS3,
    ReactJS,
    Angular,
    JavaScript,
    jQuery,
    SidebarWeb,
    SidebarMobile,
    SidebarCloud,
    SidebarGame,
    SidebarTesting
} from 'assets/img'

export default {
    pageTop: {
        title: 'Transformative Solutions',
        text: (
            <>
                First line: We are experts in blending design and technology to make exceptional products.
                <br /> <br /> We join hands and go above & beyond to bring the ideas to life. Through our exceptional
                planning, top-notch engineering teams and flexible engagement models, we empower digital transformations
                for all size businesses.
            </>
        ),
        imgUrl: ServicesPageTop,
        alt: 'Services'
    },
    blackSection: {
        text: (
            <>
                Your vision, <span className="text-white">our team</span> – we go beyond just doing the work to
                collaborate with you.
            </>
        ),
        buttonText: "LET'S TALK"
    },
    greenSection: {
        features: [
            {
                imgUrl: Approach,
                alt: 'Approach',
                title: 'OUR APPROACH',
                text: `We guarantee high-quality solutions and products through our ideation, innovation and execution. We adopt an Agile approach to ensure we keep flexible and continuously iterate on our work to incorporate your feedback, ensuring you'll be thrilled with the final solution.`
            },
            {
                imgUrl: Capabilities,
                alt: 'Capabilities',
                title: 'OUR CAPABILITIES',
                text: 'The Digital Trons team comprises talented designers, experienced developers, and passionate product people. We are well versed in web & mobile development, manual & automation QA, native & hybrid mobile apps, AWS & google clouds.'
            }
        ]
    },
    offer: {
        title: 'We Offer',
        features: [
            {
                imgUrl: WebDevelopment,
                alt: 'Web Development',
                title: 'WEB DEVELOPMENT',
                text: `In today's marketplace, it's important that your company has access to the most up-to-date Internet technologies. We utilize modern technologies to build scalable, enterprise-grade solutions that stand up to rigorous use across a variety of verticals. Our web development solutions are developed with your business processes in mind. Our team will help you develop customized, robust and high performance web products.`
            },
            {
                imgUrl: MobileDev,
                alt: 'Mobile Development',
                title: 'MOBILE DEVELOPMENT',
                text: `Digital Trons specializes in native & hybrid mobile app development. Our talented minds have successfully developed and delivered mobile apps for all size companies – from large scale to startups. Apps include third party integrations like payment gateways,  maps & geo-tracking, real-time communication like chat and push notifications to name few.  we map out the experiences that drive customers to be more loyal, valuable, and engaged.`
            },
            {
                imgUrl: Cloud,
                alt: 'Cloud Computing',
                title: 'CLOUD COMPUTING',
                text: `Transitioning all or part of infrastructure to cloud computing brings many advantages to the businesses. Whether you are looking to migrate to cloud infrastructure or build cloud native apps, Our consultants can help you evaluate and choose the best forward-thinking cloud strategy for your company. You will start reaping the benefits in terms of agility, scalability and cost savings that cloud computing provides.`
            },
            {
                imgUrl: QATesting,
                alt: 'QA Testing',
                title: 'QA TESTING',
                text: `Our QA team helps to unearth the issues before your customers do. We have deep expertise with wide range of testing methodologies to ensure your software does what it meant to do and meets the end user's expectations. Our Manual testing ensures top notch quality when software is released. Our Automation team uses widely recognized tools to achieve unparalleled speed and precision.`
            }
        ]
    },
    howWeDo: {
        title: 'Technically Brilliant',
        text: 'Up-to-date across all technologies to build the best solutions. Striving to produce exceptional digital solutions to grow your business.'
    },
    technology: {
        top: {
            left: 'Technologies',
            right: 'We take great care to match the technical approach to the vision, no the other way around. We work with various advanced technologies.'
        },
        bottom: {
            tabs: [
                {
                    name: 'Web',
                    imgUrl: SidebarWeb,
                    techs: [
                        {
                            imgUrl: HTML5,
                            text: 'HTML5'
                        },
                        {
                            imgUrl: CSS3,
                            text: 'CSS3'
                        },
                        {
                            imgUrl: JavaScript,
                            text: 'JavaScript'
                        },
                        {
                            imgUrl: Angular,
                            text: 'Angular'
                        },
                        {
                            imgUrl: ReactJS,
                            text: 'ReactJS'
                        },
                        {
                            imgUrl: jQuery,
                            text: 'jQuery'
                        }
                    ]
                },
                {
                    name: 'Mobile',
                    imgUrl: SidebarMobile,
                    techs: []
                },
                {
                    name: 'Cloud',
                    imgUrl: SidebarCloud,
                    techs: []
                },
                {
                    name: 'Gaming',
                    imgUrl: SidebarGame,
                    techs: []
                },
                {
                    name: 'Testing',
                    imgUrl: SidebarTesting,
                    techs: []
                }
            ]
        }
    },
    greenSection2: {
        title: <>We are ready to get to work. Contact us to tell us more about how we can help you.</>,
        buttonText: 'Get In Touch'
    }
}
