import clsx from 'clsx'

// import BrandLogo from 'assets/img/Logo.svg'
import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
// import NavLinks from './NavLinks'
import { HeaderText } from 'assets/text'
import { ShadowedText } from 'components/atoms'
import { GreenButton } from 'components/atoms'
import { useHistory } from 'react-router'
import { BookNowButton } from 'components/atoms'
import './PageHeader.scss'
import { Link } from 'react-scroll'
export const navLinks = [
    {
        id: 1,
        text: 'Why Us',
        route: '#whyUs'
    },
    {
        id: 2,
        text: 'About Us',
        route: '#aboutus'
    },
    {
        id: 3,
        text: 'Services',
        route: '#services'
    },

    {
        id: 4,
        text: 'Our Work',
        route: '#works'
    },

    {
        id: 5,
        text: 'Contact Us',
        route: '#contact'
    }
]
const PageHeader = () => {
    const [hamActive, setHamActive] = useState(false)
    const [resHam, setResHam] = useState(false)
    const [hammable, setHammable] = useState(false)
    const navLinkPos = `${hammable ? 'top-20 md:top-24 left-0 transform translate-y-0' : ''} ${
        hamActive ? 'translate-x-0' : 'translate-x-full'
    }`
    let navLinkPos2
    if (resHam == true) {
        navLinkPos2 = `${resHam ? 'translate-x-full' : ''}`
    } else {
        navLinkPos2 = `${resHam ? '' : 'translate-x-0'}`
    }

    useEffect(() => {
        const windowResizeOps = () => {
            if (window.innerWidth >= 1024) {
                setHamActive(false)
                setHammable(false)
            } else {
                setHammable(true)
            }
        }
        windowResizeOps()
        window.addEventListener('resize', windowResizeOps)
        return () => window.removeEventListener('resize', windowResizeOps)
    })

    const { brandLogo, buttonText } = HeaderText
    console.log(navLinkPos)
    console.log(navLinkPos2)
    console.log(resHam)

    return (
        <header
            className=" block-black w-full top-0 left-0  header z-50 h-20 md:h-24 border-box lg:px-8"
            style={{ boxShadow: '0px 10px 13px rgba(0, 0, 0, 0.25)' }}
        >
            <div className="relative h-full">
                <div className="grid grid-cols-9 px-8 py-4 items-center h-full">
                    <div className="col-span-5 md:col-span-2 grid justify-center w-full">
                        <a href="/">
                            <img className="w-full cursor-pointer" src={brandLogo} alt="Brand Logo" />
                        </a>
                    </div>
                    <div
                        className={clsx(
                            'lg:col-start-5 lg:col-span-5 n-link absolute lg:relative lg:flex flex-1 flex-col lg:flex-row w-full transition delay-300 ham ease-in gap-4',
                            navLinkPos,
                            navLinkPos2
                        )}
                        style={{ minWidth: 100 }}
                    >
                        <div className="navlink flex justify-center w-full" style={{ fontWeight: 'bold' }}>
                            <div style={{ width: '100%' }}>
                                <div className="flex flex-1 flex-col lg:flex-row justify-center items-center w-full">
                                    {navLinks.map(({ text, route }) => (
                                        <a
                                            href={route}
                                            key={Math.random()}
                                            onClick={() => setResHam(true)}
                                            className={clsx(
                                                'navlink-block h-full block lg:p-0 border-b lg:border-none flex items-center justify-center text-light'
                                            )}
                                        >
                                            <ShadowedText
                                                bgColor="bgcolor-yellow"
                                                className="section-heading-large n-link-text"
                                            >
                                                {text}
                                            </ShadowedText>
                                        </a>
                                    ))}
                                    {/* <div className="z-20 h-full block p-2 pt-0 lg:p-0 border-b lg:border-none flex items-center justify-center">
                        <div className="interactiion flex justify-center pt-3 pb-3">
                            <GreenButton style={{ width: '200px' }}>CONSULT WITH US</GreenButton>
                        </div>
                    </div> */}
                                    <div className="contact-us-mobile  flex items-center justify-center lg:block interactiion text-center  ml-7 lg:col-span-2">
                                        <a href="https://calendly.com/krunal-thakkar/free-consultation" target="blank">
                                            <GreenButton style={{ width: '200px', fontSize: '18px' }}>
                                                BOOK A SESSION
                                            </GreenButton>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute lg:hidden top-4 md:top-6 right-4 w-16">
                    <button
                        className="flex flex-col items-end gap-1 w-full h-full p-4"
                        onClick={() => {
                            setHamActive(true)
                            setResHam(false)
                        }}
                    >
                        <span className="w-3/5 border-t border-green-400" />
                        <span className="w-4/5 border-t border-green-400" />
                        <span className="w-full border-t border-green-400" />
                    </button>
                </div>
            </div>
        </header>
    )
}

export default PageHeader
