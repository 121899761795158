import {
    Audience,
    CloudEngine,
    ComedyTragedy,
    MachineDev,
    NodedLoop,
    PageDogear,
    Rocket,
    Shielded,
    StartupPageTop,
    Time,
    WheelWind
} from 'assets/img'

export default {
    pageTop: {
        title: (
            <>
                Apps and <span className="text-white">software development</span> for startups
            </>
        ),
        text: 'Digiral Trons offers comprehensive digital & technology solutions to startups, entreprenuers, and growing businesses around the world.',
        imgUrl: StartupPageTop,
        alt: 'Startup Page Top',
        features: [
            {
                imgUrl: Rocket,
                alt: 'Rocket',
                text: 'Cooperate with a dedicated web development company focused on your startup'
            },
            {
                imgUrl: WheelWind,
                alt: 'Wheel Wind',
                text: 'See tangible results of your startup app development every sprint'
            },
            {
                imgUrl: Time,
                alt: 'Time',
                text: 'Save up to 4-5 weeks on software development for startups with Startup Booster Toolkit'
            },
            {
                imgUrl: Shielded,
                alt: 'Shielded',
                text: 'Safe software development for startups with Startup Booster Toolkit'
            }
        ]
    },
    services: {
        title: 'Software Development Services for startups',
        text: 'Every app must have a well-planned and agile core for future maintenance and updates.',
        features: [
            {
                imgUrl: MachineDev,
                alt: 'Machine Development',
                title: 'MVP DEVELOPMENT',
                text: 'As a startup with a restricted budget, you need to present your idea as soon as possible. With IT Creaft, you streamline your product down to core.'
            },
            {
                imgUrl: PageDogear,
                alt: 'Page Dog Ear',
                title: 'PROJECT RESCUE',
                text: 'As a startup with a restricted budget, you need to present your idea as soon as possible. With IT Creaft, you streamline your product down to core.'
            },
            {
                imgUrl: NodedLoop,
                alt: 'Noded Loop',
                title: 'FULL-CYCLE DEVELOPMENT',
                text: 'As a startup with a restricted budget, you need to present your idea as soon as possible. With IT Creaft, you streamline your product down to core.'
            },
            {
                imgUrl: ComedyTragedy,
                alt: 'Comedy Tragedy',
                title: 'PRODUCT FEEDBADK',
                text: 'As a startup with a restricted budget, you need to present your idea as soon as possible. With IT Creaft, you streamline your product down to core.'
            },
            {
                imgUrl: Audience,
                alt: 'Audience',
                title: 'PROJECT RESCUE',
                text: 'As a startup with a restricted budget, you need to present your idea as soon as possible. With IT Creaft, you streamline your product down to core.'
            },
            {
                imgUrl: CloudEngine,
                alt: 'Cloud Engine',
                title: 'TECHNOLOGY MIGRATION',
                text: 'As a startup with a restricted budget, you need to present your idea as soon as possible. With IT Creaft, you streamline your product down to core.'
            }
        ]
    }
}
